import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Card,
} from "react-bootstrap";
import "./Videos.css";
import { Link } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";

import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
export default function Home() {
  const { t, i18n } = useTranslation();
  return (
    <Container fluid className=" textura">
      {/* <Row style={{ alignItems: "center", width: "100%" }}>
        <Col xs={2}>
          {" "}
         
        </Col>
      </Row> */}
      <Row>
        <Col xl={6} xs={12} className="d-flex justify-content-center">
          {" "}
          <Row style={{ alignItems: "center", width: "100%" }}>
            <Col xs={12}>
              <Image
                className="imagenhasg mt-5"
                // style={{ width: "200px" }}
                //   className="animate__animated animate__fadeIn animate__slow "
                src={t("home.imagenHagstag")}
              ></Image>
            </Col>

            <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <ReactPlayer
                className="react-player mt-5 mainvideo
                "
                url="https://youtu.be/kid6bRDE0HE"
              />
            </Col>

            <Col xs={12} className="mt-5 justify-content-center d-flex">
              <Link to={"/tu_actitud"} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#E2E8AF",
                    fontSize: "18px",
                    color: "#000",
                    borderRadius: "1em",
                    // border: "1px solid #000",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  <div> {t("home.tu_actitud")}</div>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              className="mt-5 mb-5 justify-content-center d-flex "
              style={{
                borderRadius: "2em",
                backgroundColor: "#E2E8AF",
                padding: "5px 25px",
              }}
            >
              {t("actitud.texto_actitud")}
            </Col>
          </Row>
        </Col>

        <Col
          xl={6}
          xs={12}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            // marginTop: "10px",
            // padding: "20px 5px",
          }}
        >
          <Row
            style={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              // marginTop: "10px",
              // padding: "20px 5px",
            }}
          >
            {" "}
            <Col md={6} xs={12} className="mt-3">
              <Card>
                <Card.Header style={{ height: "300px" }}>
                  {" "}
                  <ReactPlayer
                    className="react-player pildoras "
                    url="https://www.youtube.com/watch?v=kp0WSsCyxw8"
                  />
                </Card.Header>
              </Card>
            </Col>
            <Col md={6} xs={12} className="mt-3">
              <Card>
                <Card.Header style={{ height: "300px" }}>
                  {" "}
                  <ReactPlayer
                    className="react-player pildoras "
                    url="https://www.youtube.com/watch?v=0UMOTKvLG4g"
                  />
                </Card.Header>
              </Card>
            </Col>
            <Col md={6} xs={12} className="mt-3">
              <Card>
                <Card.Header style={{ height: "300px" }}>
                  {" "}
                  <ReactPlayer
                    className="react-player pildoras "
                    url="https://youtu.be/MJZ3KBGN9GM"
                  />
                </Card.Header>
              </Card>
            </Col>
            <Col md={6} xs={12} className="mt-3">
              <Card>
                <Card.Header style={{ height: "300px" }}>
                  {" "}
                  <ReactPlayer
                    className="react-player pildoras "
                    url="https://youtu.be/DIUnsHjJ5gE"
                  />
                </Card.Header>
              </Card>
            </Col>
            <Col md={6} xs={12} className="mt-3">
              <Card>
                <Card.Header style={{ height: "300px" }}>
                  {" "}
                  <ReactPlayer
                    className="react-player pildoras "
                    url="https://youtu.be/iTvE0927_kI"
                  />
                </Card.Header>
              </Card>
            </Col>
            {/* // <Col md={6} xs={12} className="mt-3">
            //   <Card>
            //     <Card.Header style={{ height: "200px" }}>
            //       {" "}
            //       <ReactPlayer
            //         className="react-player pildoras"
            //         url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            //       />
            //     </Card.Header>

            //     <Card.Body>
            //       <Card.Title>Card title</Card.Title>
            //       <Card.Text>
            //         This is a wider card with supporting text below as a natural
            //         lead-in to additional content. This content is a little bit
            //         longer.
            //       </Card.Text>
            //     </Card.Body>
            //   </Card>
            // </Col>
            // <Col md={6} xs={12} className="mt-3">
            //   <Card>
            //     <Card.Header style={{ height: "200px" }}>
            //       {" "}
            //       <ReactPlayer
            //         className="react-player pildoras"
            //         url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            //       />
            //     </Card.Header>

            //     <Card.Body>
            //       <Card.Title>Card title</Card.Title>
            //       <Card.Text>
            //         This is a wider card with supporting text below as a natural
            //         lead-in to additional content. This content is a little bit
            //         longer.
            //       </Card.Text>
            //     </Card.Body>
            //   </Card>
            // </Col>
            // <Col md={6} xs={12} className="mt-3">
            //   <Card>
            //     <Card.Header style={{ height: "200px" }}>
            //       {" "}
            //       <ReactPlayer
            //         className="react-player pildoras"
            //         url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            //       />
            //     </Card.Header>

            //     <Card.Body>
            //       <Card.Title>Card title</Card.Title>
            //       <Card.Text>
            //         This is a wider card with supporting text below as a natural
            //         lead-in to additional content. This content is a little bit
            //         longer.
            //       </Card.Text>
            //     </Card.Body>
            //   </Card>
            // </Col>  */}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
