import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Actitud from "./Pages/Actitud/Actitud";
import Home from "./Pages/Home/Home";
import "./firebase.js";
import Videos from "./Pages/Videos/Videos";
const Loader = () => <div>loading...</div>;
export default function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <div>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Videos />} />
            <Route path="/tu_actitud" element={<Actitud />} />
          </Routes>
        </div>
      </Suspense>
    </Router>
  );
}
