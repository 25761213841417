import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Card,
  Form,
} from "react-bootstrap";
import "./Actitud.css";
import { Link } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { MdKeyboardBackspace } from "react-icons/md";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";

import { db, storage } from "../../firebase";
import ReactLoading from "react-loading";
function imagenActitud(actitud, traducciones) {
  switch (actitud) {
    case "valiente":
      return traducciones("actitud.valiente");
      break;

    case "honrado":
      return traducciones("actitud.honrado");
      break;
    case "honesto":
      return traducciones("actitud.honesto");
      break;
    case "humildad":
      return traducciones("actitud.humildad");
      break;
    case "sencillez":
      return traducciones("actitud.sencillez");
      break;

    default:
      return traducciones("actitud.sencillez");
      break;
  }
}

function colorActitud(actitud) {
  switch (actitud) {
    case "valiente":
      return "#F28400";
      break;

    case "honrado":
      return "#FFDE59";
      break;
    case "honesto":
      return "#D01442";
      break;
    case "humildad":
      return "#E2E8AF";
      break;
    case "sencillez":
      return "#F28400";
      break;

    default:
      return "#E2E8AF";
      break;
  }
}
function CargarIncidencias(props) {
  let incidenciasList, array;

  if (props.incidencias != null) {
    array = Object.values(props.incidencias);

    if (array.length !== 0) {
      // array = _.sortBy(array, "creado").reverse();

      incidenciasList = array.map((incidencia) => {
        return (
          <Col xl={4} md={6} xs={12} className="mt-3">
            <Card
              style={{ borderRadius: "1em", width: "100%", height: "170px" }}
            >
              <Card.Header
                style={{
                  backgroundColor: colorActitud(incidencia.actitud),
                  textAlign: "center",
                  borderRadius: "1em",
                  padding: "10px",
                }}
              >
                <Image
                  style={{ height: "20px" }}
                  //   className="animate__animated animate__fadeIn animate__slow "
                  src={imagenActitud(incidencia.actitud, props.traducciones)}
                ></Image>
              </Card.Header>
              <Card.Body>
                <Card.Text>{incidencia.proposito}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      });
    } else {
      incidenciasList = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <ReactLoading
            type={"spin"}
            color={"#ffffff"}
            height={"64px"}
            width={"64px"}
          />
        </div>
      );
    }
  } else {
    incidenciasList = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactLoading
          type={"spin"}
          color={"#ffffff"}
          height={"64px"}
          width={"64px"}
        />
      </div>
    );
  }

  return incidenciasList;
}
function calcularEdad(fecha) {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
}
export default function Actitud() {
  const { t, i18n } = useTranslation();
  const [select, setSelect] = React.useState("");
  const [fecha, setFecha] = React.useState();
  const [docs, setDocs] = React.useState([]);
  const [departamento, setDepartamento] = React.useState("");
  const [archivo, setArchivo] = React.useState([]);
  const handleOnChangeselect = (e) => {
    e.preventDefault();

    setSelect(e.target.value);
  };

  const handleOnChangeselectDepart = (e) => {
    e.preventDefault();

    setDepartamento(e.target.value);
  };

  const subirImagenes = (docRef) => {
    const promises = [];

    console.log(archivo);
    const metadata = {
      contentType: "application/pdf",
    };
    archivo.map((image) => {
      var storageRef = ref(storage, `curriculums/${docRef.id}_${image.name}`);
      // var uploadTask = storageRef.child(image.name).put(image);
      const uploadTask = uploadBytesResumable(storageRef, image, metadata);

      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");

          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            updateDoc(docRef, {
              archivos: arrayUnion(downloadURL),
            });
          });
        }
      );
    });

    Promise.all(promises)
      .then(() => {
        toast.success("Enviado con éxito", {
          duration: 3000,
        });
      })
      .catch((err) => console.log(err));
  };

  const subirDatosIncidencia = async (values) => {
    console.log(values);
    const actitud = select;
    const selecfecha = fecha;
    const selecdepartamento = departamento;
    // console.log(selecfecha);

    try {
      const docRef = await addDoc(collection(db, "Actitudes"), {
        id: uuidv4(),
        creado: serverTimestamp(),
        nombre: values.nombre.toString(),
        apellidos: values.apellidos.toString(),
        email: values.email.toString(),
        actitud: actitud,
        proposito: values.proposito.toString(),
        departamento: selecdepartamento,
        fecha_nacimiento: selecfecha,
      });

      if (archivo.length !== 0) {
        subirImagenes(docRef);
      } else {
        toast.success("Enviado con éxito", {
          duration: 3000,
        });
      }

      values.nombre = "";
      values.apellidos = "";
      values.email = "";
      setSelect();
      values.proposito = "";
      setFecha();
      setArchivo();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "Actitudes")),
      (querySnapshot) => {
        const cities = [];

        querySnapshot.forEach((doc) => {
          cities.push(doc.data());
        });
        console.log(cities);

        setDocs(cities);
      }
    );

    return unsubscribe;
  }, [docs.length]);

  const handleOnChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setArchivo((prevState) => [...prevState, newImage]);
    }
  };
  return (
    <Container fluid className=" textura">
      <Toaster position="top-right" />
      <Row>
        <Col xl={6} xs={12} className=" d-flex justify-content-center">
          {" "}
          <Row style={{ alignItems: "flex-start", width: "100%" }}>
            <Col xs={12}>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    fontSize: "18px",
                    color: "#fff",
                    borderRadius: "1em",
                    padding: "10px 20px",
                  }}
                >
                  <MdKeyboardBackspace
                    color={"#000"}
                    size={"20px"}
                    style={{ marginRight: "5px" }}
                  />
                  <div> {t("actitud.volver")}</div>
                </div>
              </Link>
              <Image
                className="imagenhasg mt-5"
                // style={{ width: "200px" }}
                //   className="animate__animated animate__fadeIn animate__slow "
                src={t("actitud.imagenAtitud")}
              ></Image>
              <div
                className="mt-3 text-justify"
                style={{
                  borderRadius: "2em",
                  backgroundColor: "#E2E8AF",
                  padding: "5px 25px",
                }}
              >
                {t("actitud.texto_actitud")}
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xl={6}
          xs={12}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            // marginTop: "10px",
            padding: "50px 15px",
          }}
        >
          {/* <Row
            style={{
              alignItems: "center",
              width: "100%",
              // marginTop: "10px",
              // padding: "20px 5px",
            }}
          >
            {" "}
            <Col>
              <Card
                style={{
                  backgroundColor: "transparent",
                  border: "0px solid",
                }}
              >
                <Card.Body>
                  <Formik
                    initialValues={{ email: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (
                        values.nombre == "" ||
                        values.apellidos == "" ||
                        values.email == "" ||
                        select == "" ||
                        departamento == "" ||
                        values.proposito == "" ||
                        fecha == ""
                      ) {
                        console.log("no campos");
                        toast.error("Por favor completa todos los campos", {
                          duration: 3000,
                        });
                        setSubmitting(false);
                      } else {
                        if (calcularEdad(fecha) < 18) {
                          toast.error(
                            "Para participar debes de ser mayor de edad",
                            {
                              duration: 3000,
                            }
                          );
                          setSubmitting(false);
                        } else {
                          subirDatosIncidencia(values);
                          // setTimeout(() => {
                          //   alert(JSON.stringify(values, null, 2));
                          setSubmitting(false);
                        }
                      }

                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                   
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6} xs={12}>
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("actitud.nombre")}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="nombre"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nombre}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("actitud.apellido")}
                              </label>

                              <input
                                className="form-control"
                                type="text"
                                name="apellidos"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.apellidos}
                              />
                            </div>
                          </Col>
                          <Col md={6} xs={12}>
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("actitud.fecha_nacimiento")}
                              </label>

                              <input
                                className="form-control"
                                type="date"
                                name="fecha"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fecha}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setFecha(e.target.value);
                                }}
                              />
                              {errors.fecha && touched.fecha && errors.fecha}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("actitud.email")}
                              </label>

                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && errors.email}
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {t("actitud.tu_actitud")}
                          </label>
                          <select
                            className="form-select"
                            value={select}
                            onChange={(e) => {
                              handleOnChangeselect(e);
                            }}
                          >
                            <option value="valiente" selected>
                              {" "}
                              {t("actitud.valientet")}
                            </option>
                            <option value="honesto">
                              {" "}
                              {t("actitud.honestot")}
                            </option>
                            <option value="honrado">
                              {" "}
                              {t("actitud.honradot")}
                            </option>
                            <option value="sencillez">
                              {" "}
                              {t("actitud.sencillezt")}
                            </option>
                            <option value="humildad">
                              {" "}
                              {t("actitud.humildadt")}
                            </option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {t("actitud.departamento")}
                          </label>
                          <select
                            className="form-select"
                            value={departamento}
                            onChange={(e) => {
                              handleOnChangeselectDepart(e);
                            }}
                          >
                            <option value="cocinaRest" selected>
                              {" "}
                              {t("actitud.cocinaRest")}
                            </option>
                            <option value="cocinaLGFM">
                              {" "}
                              {t("actitud.cocinaLGFM")}
                            </option>
                            <option value="salaRest">
                              {" "}
                              {t("actitud.salaRest")}
                            </option>
                            <option value="marketing">
                              {" "}
                              {t("actitud.marketing")}
                            </option>
                            <option value="compras">
                              {" "}
                              {t("actitud.compras")}
                            </option>
                            <option value="rrhh"> {t("actitud.rrhh")}</option>
                            <option value="iD"> {t("actitud.iD")}</option>
                            <option value="GuestRelation">
                              {" "}
                              {t("actitud.GuestRelation")}
                            </option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {t("actitud.tu_proposito")}
                          </label>
                          <textarea
                            className="form-control"
                            type="text"
                            name="proposito"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.proposito}
                            rows="4"
                            cols="50"
                            maxLength="200"
                          ></textarea>
                        </div>
                     
                        <div className="mb-3">
                          <Form.Group className="position-relative mb-3">
                            <Form.Label>{t("actitud.curriculum")}</Form.Label>
                            <Form.Control
                              type="file"
                              name="file"
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                              // isInvalid={!!errors.file}
                              multiple
                            />
                          </Form.Group>
                        </div>
                        <div className="mb-3">
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/actitud-landing.appspot.com/o/BASES%20LEGALES%20SORTEO%20BECA.pdf?alt=media&token=32753b60-2692-41f8-8ceb-6039e7f39524"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "black" }}
                          >
                            {" "}
                            Consulta las bases legales
                          </a>
                        </div>
                        <Button
                          variant="secondary"
                          type="submit"
                          disabled={isSubmitting}
                          className="w-100 mt-3"
                        >
                          {t("actitud.enviar")}
                        </Button>{" "}
                      </form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}

          <Image
            className="imagenhasg mt-5"
            // style={{ width: "200px" }}
            //   className="animate__animated animate__fadeIn animate__slow "
            src={t("actitud.gracias")}
          ></Image>
        </Col>
        <Col xs={12} className="mt-2 p-2 d-flex justify-content-center">
          <Row className="w-100">
            <CargarIncidencias
              incidencias={docs}
              traducciones={t}
            ></CargarIncidencias>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
